import api from "@/common/services/api";

export default new class {
    baseUrl = "";

    getSelectData(data) {
        return api.instance.get("/get-select-data", {
            params: data
        });
    }
    getNotifications(data) {
        return api.instance.get("/notifications",{
            params: data
        })
    }

    updateNotificationStatus(id) {
        return api.instance.put("/notifications/" + id)
    }

};