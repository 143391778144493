import notificationService from "../../services"
// import notificationRouting from "@/common/services/notification-routing"
export default {
    data() {
        return {
            search: "",
            searchedResultShow: false,
            icons: {
                "commission": 'fa font-20 fa-percent color-blue-dark',
                "payment": 'fa font-20 fa-university color-green-dark',
                "customer": 'fa font-20 fa-user color-dark'
            },
            notifications: [],
            meta: {},
            loading: false
        };
    },
    mounted() {
        this.getNotifications();
        this.getNextRecord();
    },
    methods: {
        getNotifications() {
            this.showLoader(true);
            notificationService.getNotifications({ "search": this.search }).then(response => {
                this.hideLoader();
                this.notifications = response.data.data;
                this.meta = response.data.meta;

            })
        },
        updateNotificationStatus(notification, index) {
            if (notification.notification_data != null) {
                this.$notificationRouting.routeNotification(notification.notification_data);
            }
            if (notification.status != "read") {
                this.showLoader(true);
                notificationService.updateNotificationStatus(notification.id).then(() => {
                    this.hideLoader();
                    this.notifications[ index ].status = "read";
                })
            }
        },
        getNextRecord() {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.meta.current_page != this.meta.last_page && !this.loading) {
                    this.showLoader(true);
                    this.loading = true;
                    notificationService.getNotifications(
                        {
                            'search': this.search,
                            'page': this.meta.current_page + 1
                        }).then(response => {
                            this.hideLoader();
                            this.notifications.push(...response.data.data);
                            this.meta = response.data.meta;
                            this.loading = false;
                        })
                }
            }
        },
        filterUpdate() {
            this.meta.current_page = 1;
            this.getNotifications();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.meta.current_page = 1;
            this.search = "";
            this.getNotifications();
            this.searchedResultShow = false;
        },
    }
}